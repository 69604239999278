<template>
  <div>
    <el-breadcrumb style="margin: 0 0 20px 10px" separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
      <el-breadcrumb-item>库位列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">库位列表</div>
        <div>
          <el-button type="add" icon="el-icon-circle-plus-outline" @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box11">
          <span>库位名称：</span>
          <el-input
            class="int_width"
            v-model="name"
            placeholder="请输入库位名称"
            clearable
          ></el-input>
          <el-button
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询
          </el-button>
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            align="center"
            prop="ware_name"
            label="名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="weight"
            label="库存总量(吨)"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="创建人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="create_at"
            label="创建时间"
          ></el-table-column>
          <el-table-column width="400px" align="center" label="操作">
            <template v-slot="scope">
              <el-button
                size="mini"
                type="primary"
                icon="iconfont icon-bianxie"
                @click="edit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                icon="iconfont icon-shanchu"
                @click="del(scope.row.id)"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="success"
                icon="iconfont "
                @click="bianhua(scope.row.id)"
                >库位变化表</el-button
              >
              <el-button
                size="mini"
                type="success"
                icon="iconfont "
                @click="chanpin(scope.row.id)"
                >产品列表</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 添加库位 -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible1"
        width="1100px"
        :before-close="handleClose1"
      >
        <div class="xian2"></div>
        <el-form
          ref="form1"
          :rules="rules1"
          :model="addform1"
          label-width="200px"
        >
          <el-form-item label="库位名称：" prop="name">
            <el-input class="int" v-model="addform1.name"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              class="int"
              type="textarea"
              :autosize="{ minRows: 3 }"
              v-model="addform1.remark"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button type="cha" class="add_btn111" @click="addkuwei"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <!-- 库位变化表 -->
      <el-dialog
        title="库位变化表"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible2"
        width="1100px"
        :before-close="handleClose2"
      >
        <div class="xian2"></div>
        <div class="int_box22">
        <div class="int_box11">
          <span>产品名称：</span>
          <el-input
            class="int_width"
            v-model="name1"
            placeholder="请输入产品名称"
            clearable
          ></el-input>
          <el-button
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun1"
            >查询
          </el-button>
        </div>
      </div>
        <template>
          <el-table
            :header-cell-style="tableHeader"
            :data="warehouseList"
            style="width: 100%"
            stripe
          >
            <el-table-column
              align="center"
              prop="product_name"
              label="产品"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="num"
              label="数量(吨)"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="username"
              label="操作人"
            ></el-table-column>
            <el-table-column align="center" label="操作类型">
              <template v-slot="scope">
                <div>{{ scope.row.operate_type === 1 ? '手动' : '扫码' }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="出入库类型">
              <template v-slot="scope">
                <div>{{ scope.row.trace_type === 1 ? '入库' : '出库' }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="create_at"
              label="创建时间"
            ></el-table-column>
          </el-table>
        </template>
        <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="page1"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageNumber1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total1"
        ></el-pagination>
      </div>
      </el-dialog>
      <!-- 库位对应产品表 -->
      <el-dialog
        title="产品列表"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible3"
        width="1100px"
        :before-close="handleClose3"
      >
        <div class="xian2"></div>
        <div class="int_box22">
        <div class="int_box11">
          <span>产品名称：</span>
          <el-input
            class="int_width"
            v-model="name2"
            placeholder="请输入产品名称"
            clearable
          ></el-input>
          <el-button
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun2"
            >查询
          </el-button>
        </div>
      </div>
        <template>
          <el-table
            :header-cell-style="tableHeader"
            :data="getPorductList"
            style="width: 100%"
            stripe
          >
            <el-table-column
              align="center"
              prop="product_name"
              label="产品名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="inventory"
              label="库存(吨)"
            ></el-table-column>
           
          </el-table>
        </template>
        <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="page1"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageNumber2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total2"
        ></el-pagination>
      </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      pageNumber: 5,
      name: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      // 使用配置参数
      dialogVisible1: false,
      addform1: {
        name: '',
        remark: '',
      },
      rules1: {
        name: [{ required: true, message: '请输入库位名称', trigger: 'blur' }],
      },
      text: '添加',
      //   库位变化表参数
      dialogVisible2: false,
      page1: 1,
      pageNumber1: 5,
      name1: '',
      warehouseList: [],
      total1: 0,
      ids: '',
    //   库位对应的产品列表
        dialogVisible3: false,
      page2: 1,
      pageNumber2: 5,
      name2: '',
      getPorductList: [],
      total2: 0,
      ids2: '',
    }
  },
  created() {
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    handleSizeChange1(val) {
      this.pageNumber1 = val
      this.warehouse()
    },
    handleCurrentChange1(val) {
      this.page1 = val
      this.warehouse()
    },
    handleSizeChange2(val) {
      this.pageNumber2 = val
      this.warehouse()
    },
    handleCurrentChange2(val) {
      this.page2 = val
      this.warehouse()
    },

    handleClose1() {
      this.dialogVisible1 = false
      this.$refs.form1.resetFields()
      this.addform1 = {
        name: '',
        remark: '',
        id: '',
      }
    },
    handleClose2() {
      this.dialogVisible2 = false
      this.warehouseList = []
      this.total1 = 0
      ;(this.page1 = 1), (this.pageNumber1 = 5), (this.name1 = '')
    },
    handleClose3() {
      this.dialogVisible3 = false
      this.getPorductList = []
      this.total2 = 0
      ;(this.page2 = 1), (this.pageNumber2 = 5), (this.name2 = '')
    },

    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/warehouse/index', {
        params: {
          page: this.page,
          pageNumber: this.pageNumber,
          name: this.name,
        },
      })
      console.log(11, res)
      this.userList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    // 查询
    chaxun1() {
      this.page1 = 1
      this.warehouse()
    },
    // 查询
    chaxun2() {
      this.page2 = 1
      this.getPorduct()
    },

    // 点击入库
    add() {
      this.dialogVisible1 = true
      this.text = '添加'
    },

    
    // 添加、编辑
    addkuwei() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')

        const { data: res } = await this.$http.post(
          'api/warehouse/saves',
          this.addform1
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible1 = false
        this.$refs.form1.resetFields()
        this.page = 1
        this.user()
      })
    },
    // 点击编辑
    edit(id) {
      this.text = '编辑'
      this.editMessage(id)
      this.dialogVisible1 = true
    },
    // 编辑获取信息
    async editMessage(id) {
      const { data: res } = await this.$http.post('api/warehouse/getMessage', {
        id: id,
      })
      console.log(11, res)
      if (res.code != 200) return this.$message.error(res.msg)
      this.addform1 = {
        name: res.data.ware_name,
        remark: res.data.remark,
        id: id
      }
    },
    // 删除
    del(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post('api/warehouse/delete', {
            id: id,
          })
          if (res.code != 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.page = 1
          this.user()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 点击展示库位变化表
    bianhua(id) {
        this.ids = id
      this.warehouse()
      this.dialogVisible2 = true
    },
    // 库位变化表
    async warehouse() {
      const { data: res } = await this.$http.get('api/warehouse/changeLog', {
        params: {
          page: this.page1,
          pageNumber: this.pageNumber1,
          name: this.name1,
          id: this.ids,
        },
      })
      console.log(11, res)
      this.warehouseList = res.data.rows
      this.total1 = res.data.total
    },
    // 点击产品列表
    chanpin(id) {
        this.ids2 = id
        this.getPorduct()
        this.dialogVisible3 = true
        
    },
    // 库位对应的产品表
    async getPorduct() {
      const { data: res } = await this.$http.get('api/warehouse/getPorduct', {
        params: {
          page: this.page2,
          pageNumber: this.pageNumber2,
          name: this.name2,
          id: this.ids2,
        },
      })
      console.log(11, res)
      this.getPorductList = res.data.rows
      this.total2 = res.data.total
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 60px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 18px;
      padding: 4px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 25px 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box22 {
    padding: 0 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box11 {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .int_width {
    width: 300px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    width: 140px;
  }
}
</style>
